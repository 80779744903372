.App {
  text-align: center;

  overflow-x: hidden;
}

.background-img {
  z-index: -1;

  filter: blur(0px) brightness(75%) grayscale(50%);

  object-fit: contain;
  width: auto;
  height: 50vh;

  left: -5vh;
  top: 50px;
}

.background-img-content {
  top: 10%;
}

.card {
  font-family: "Roboto Mono";

  background-color: #025a1b;
  color: white;
}